const config = {
  siteTitle: "Ujjwal Arora", // Site title.
  siteTitleShort: "Ujjwal Arora", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "aroraujjwal7", // Alternative site title for SEO.
  siteLogo: "/img/site-logo.png", // Logo used for SEO and manifest.
  siteUrl: "https://aroraujjwal7.com", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-material-starter/.
  fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
  siteDescription: "Innovate!! Code!! Repeat!!", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteFBAppID: "", // FB Application ID for using app insights
  siteGATrackingID: "UA-151741314-1", // Tracking code ID for google analytics.
  disqusShortname: "aroraujjwal7", // Disqus shortname.
  postDefaultCategoryID: "Tech", // Default category for posts.
  postDefaultAuthor: 'Ujjwal Arora',
  postDefaultAuthorAvatar: "/img/site-logo.png",
  dateFromFormat: "DD-MM-YYYY", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userName: "Material User", // Username to display in the author segment.
  userEmail: "kronosdevstudio@gmail.com", // Email used for RSS feed's author segment
  userTwitter: "@kronosdevstudio", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "North Pole, Earth", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      title: "LinkedIn",
      link: "https://www.linkedin.com/in/aroraujjwal7",
      icon: 'FaLinkedin'
    }
  ],
  copyright: "Copyright © 2019. Material User" // Copyright string for the footer of the website and RSS feed.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else if (config.pathPrefix !== "") {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
